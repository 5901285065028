#about {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    .about__img {
        position: relative;
        &__ex {
            position: absolute;
            display: flex;
            flex-direction: column;
            width: 200px;
            height: 200px;
            bottom: 0;
            transform: translate(-90px, 60px);
            border-radius: 10px;
            padding: 35px 0 0 20px;
            background-color: $lightBlack;
            z-index: 22;
            cursor: pointer;
            span {
                text-align: left;
                position: relative;
                @include par(30px, 700, $white);
                &:first-child {
                    font-size: 45px;
                    &::before {
                        position: absolute;
                        display: block;
                        content: "";
                        width: 20px;
                        height: 40px;
                        background-color: $white;
                        bottom: 0;
                        transform: translate(10px, -63px);
                    }
                    &::after {
                        position: absolute;
                        display: block;
                        content: "";
                        width: 20px;
                        height: 40px;
                        background-color: $white;
                        bottom: 0;
                        transform: translate(50px, -63px);
                    }
                }
                &:nth-child(2)::before {
                    position: absolute;
                    display: block;
                    content: "";
                    width: 20px;
                    height: 40px;
                    background-color: $white;
                    bottom: 0;
                    transform: translate(130px, -109px);
                }
                &:nth-child(2)::after {
                    position: absolute;
                    display: block;
                    content: "";
                    width: 20px;
                    height: 40px;
                    background-color: $white;
                    bottom: 0;
                    transform: translate(90px, -109px);
                }
            }
        }
        img {
            width: 550px;
            filter: grayscale(10);
        }
    }
    .about__details {
        h2 {
            width: 390px;
            @include par(40px, 600, $white);
            margin-bottom: 20px;
            line-height: 1.3;
        }
        p {
            width: 571px;
            @include par(18px, 300, $white);
        }
        ul {
            margin-top: 20px;
            li {
                position: relative;
                @include par(18px, 400, $white);
                &:not(:last-child) {
                    margin-bottom: 45px;
                }
                &::before {
                    content: "";
                    position: absolute;
                    display: block;
                    height: 5px;
                    width: 548px;
                    background-color: $gray;
                    bottom: 0;
                    transform: translateY(15px);
                }
                &::after {
                    content: "";
                    position: absolute;
                    display: block;
                    height: 5px;
                    background: linear-gradient(to left, orangered, yellow);
                    z-index: 22;
                    bottom: 0;
                    transform: translateY(15px);
                }
                &:nth-child(1)::after {
                    width: 548px;
                }
                &:nth-child(2)::after {
                    width: 412px;
                }
                &:nth-child(3)::after {
                    width: 460px;
                }
                &:nth-child(4)::after {
                    width: 503px;
                }
            }
        }
        &__buttons {
            margin-top: 50px;
            button {
                width: 241px;
                height: 83px;
                border-radius: 15px;
                outline: none;
                background-color: transparent;
                @include par(22px, 400, $white);
                transition: all 0.3s ease;
                cursor: pointer;
            }
            .btn-first {
                background-color: $orange;
                border: solid 1px $orange;
                margin-right: 20px;
                &:hover {
                    background-color: transparent;
                    color: $orange;
                }
            }
            .btn-second {
                border: solid 1px $white;
                background-color: transparent;
                &:hover {
                    color: $orange;
                    border-color: $orange;
                }
            }
        }
    }
}
@media (max-width: 1500px) {
    #about {
        .about__img {
            &__ex {
                width: 180px;
                height: 180px;
                span {
                    font-size: 25px;
                    &:first-child {
                        font-size: 35px;
                        &::before {
                            transform: translate(10px, -48px);
                        }
                        &::after {
                            transform: translate(50px, -48px);
                        }
                    }
                    &:nth-child(2) {
                        &::before {
                            transform: translate(130px, -86px);
                        }
                        &::after {
                            transform: translate(90px, -86px);
                        }
                    }
                }
            }
            img {
                width: 480px;
            }
        }
        .about__details {
            h2 {
                width: 340px;
                font-size: 35px;
            }
            p {
                width: 471px;
                font-size: 15px;
            }
            ul li {
                &::before {
                    width: 490px;
                }
                &:nth-child(1)::after {
                    width: 490px;
                }
                &:nth-child(2)::after {
                    width: 390px;
                }
                &:nth-child(3)::after {
                    width: 420px;
                }
                &:nth-child(4)::after {
                    width: 460px;
                }
            }
            &__buttons {
                button {
                    width: 231px;
                    height: 73px;
                }
            }
        }
    }
}
