#services {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    h2 {
        position: relative;
        @include par(40px, 600, $white);
        margin-bottom: 10px;
    }
    p {
        @include par(18px, 300, $white);
        width: 900px;
        text-align: center;
        margin-bottom: 60px;
    }
    .skills__wrapper {
        display: grid;
        grid-template-columns: 380px 380px 380px;
        grid-template-rows: 130px 130px;
        gap: 40px;
        margin-bottom: 60px;
        &__item {
            position: relative;
            display: flex;
            align-items: center;
            background-color: $lightBlack;
            border-radius: 10px;
            cursor: pointer;
            transition: all 0.3s ease;
            &:hover {
                background-color: $orange;
                #arrow {
                    fill: $white;
                }
                .skills__wrapper__item__circle {
                    background-color: $white;
                    svg #svg-icon {
                        fill: $black;
                    }
                }
            }
            &__circle {
                width: 64px;
                height: 64px;
                background-color: $black;
                border-radius: 50%;
                display: grid;
                place-content: center;
                margin-left: 20px;
                transition: all 0.3s ease;
                svg #svg-icon {
                    fill: $white;
                    transition: all 0.3s ease;
                }
            }
            h4 {
                @include par(25px, 300, $white);
                margin-left: 23px;
                &::after {
                    content: "";
                }
            }
            .arrow {
                position: absolute;
                right: 20px;
                #arrow {
                    transition: all 0.3s ease;
                }
            }
        }
        &.open {
            position: fixed;
            width: 100vw;
            height: 100vh;
            top: 0;
            padding: 60px 158px 0;
            background-color: $black;
            z-index: 100;
        }
    }
    .btn {
        position: relative;
        width: 241px;
        height: 83px;
        border: solid 1px $orange;
        border-radius: 15px;
        background-color: $orange;
        @include par(22px, 400, $white);
        outline: none;
        cursor: pointer;
        transition: all 0.3s ease;
        &:hover {
            background-color: transparent;
            color: $orange;
        }
        &.open {
            margin-top: 20px;
            left: calc(100% + 109px);
            top: 10px;
        }
    }
}
@media (max-width: 1500px) {
    #services {
        h2 {
            font-size: 35px;
        }
        p {
            font-size: 15px;
        }
        .skills__wrapper {
            grid-template-columns: 380px 380px 380px;
            grid-template-rows: 130px 130px;
            &.open {
                justify-content: center;
                grid-template-rows: repeat(3, auto);
            }
        }
    }
}
