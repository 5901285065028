#points {
    width: 100%;
    height: 471px;
    background-size: cover;
    background-image: url("./points-backgroud.jpg");
    margin-top: 50px;
    .points__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 471px;
        &__item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 245px;
            height: 200px;
            border: dashed 2px $white;
            border-radius: 20px;
            background-color: $lightBlack;
            cursor: pointer;
            transition: all 0.3s ease;
            &:hover {
                border-color: $orange;
                background-color: $orange;
            }
            h4 {
                @include par(50px, 700, $white);
            }
            p {
                @include par(25px, 400, $white);
            }
        }
    }
}
