// colors
$orange: #ff4900;
$white: #ffff;
$gray: #707070;
$lightBlack: #111111;
$black: #000000;
$lightGray: #8c8ca5;
// mixins
@mixin par($s, $w, $c) {
    font-size: $s;
    font-weight: $w;
    color: $c;
}
