nav {
    .nav__wrapper {
        padding-top: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__navbar {
            display: flex;
            align-items: center;
            ul {
                display: flex;
                justify-self: flex-end;
                li a {
                    padding: 65px 16px 29px;
                    background-color: transparent;
                    transition: all 0.3s ease;
                    cursor: pointer;
                    &:hover {
                        background-color: $orange;
                    }
                }
                a {
                    @include par(18px, 300, $white);
                }
            }
            .btn {
                @include par(18px, 400, $white);
                padding: 21px 37px;
                border: solid 1px $orange;
                border-radius: 10px;
                background-color: $orange;
                outline: none;
                margin-left: 30px;
                cursor: pointer;
                transition: all 0.3s ease;
                &:hover {
                    background-color: transparent;
                    color: $orange;
                }
            }
        }
    }
}
@media (max-width: 1500px) {
    nav {
        .nav__wrapper {
            a {
                .logo {
                    width: 180px;
                }
            }
            &__navbar {
                ul {
                    li a {
                        padding: 65px 8px 29px;
                        font-size: 17px;
                    }
                }
                .btn {
                    font-size: 17px;
                    margin-left: 25px;
                }
            }
        }
    }
}
