header {
    position: relative;
    height: calc(100vh - 121px);
    .goToBottom {
        display: grid;
        position: absolute;
        height: 70px;
        width: 50px;
        left: 50%;
        bottom: 0;
        justify-content: center;
        align-items: center;
        border: solid 1px $orange;
        border-radius: 50px;
        color: $orange;
        font-size: 45px;
        cursor: pointer;
        transition: all 0.3s ease;
        &:hover {
            height: 80px;
        }
    }
    .header__wrapper {
        transform: translateY(180px);
        h1 {
            position: relative;
            @include par(70px, 700, $white);
            &::after {
                content: "Hello, I am";
                display: block;
                position: absolute;
                width: 200px;
                height: 64px;
                top: -70px;
                @include par(22px, 300, $white);
                padding: 17px 42px;
                background-color: $orange;
            }
            &::before {
                content: "";
                display: block;
                position: absolute;
                width: 0;
                height: 0;
                top: -10px;
                left: 75px;
                border: solid 20px transparent;
                border-top-color: $orange;
                border-bottom-width: 0;
            }
        }
        p {
            @include par(20px, 300, $white);
            margin-bottom: 50px;
        }
        .buttons {
            .btn {
                @include par(22px, 400, $white);
                width: 241px;
                height: 83px;
                border-radius: 15px;
                outline: none;
                cursor: pointer;
            }
            .btn-first {
                background-color: $orange;
                border: solid 1px $orange;
                margin-right: 20px;
                transition: all 0.3s ease;
                &:hover {
                    background-color: transparent;
                    color: $orange;
                }
            }
            .btn-second {
                background-color: transparent;
                border: solid 1px $white;
                transition: all 0.3s ease;
                &:hover {
                    color: $orange;
                    border-color: $orange;
                }
            }
        }
    }
    .images {
        z-index: -1;
        position: relative;
        left: 450px;
        bottom: 270px;
        img {
            position: absolute;
            width: 580px;
        }
        img:first-child {
            width: 950px;
        }
        .shape {
            left: 190px;
            top: 80px;
        }
    }
}
@media (max-width: 1500px) {
    header {
        .header__wrapper {
            h1 {
                font-size: 60px;
            }
            p {
                font-size: 17px;
            }
            .buttons {
                .btn {
                    font-size: 20px;
                    width: 231px;
                    height: 73px;
                }
            }
        }
    }
}
