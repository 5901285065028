*,
*::after,
*::before {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
}
body {
    background-color: black;
    font-family: "Poppins", sans-serif;
    overflow-x: hidden;
    color: white;
    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $orange;
        border-radius: 20px;
    }
    &::-webkit-scrollbar-track {
        background-color: $lightBlack;
    }
}

ul {
    list-style: none;
}
a,
a:visited {
    text-decoration: none;
}
.container {
    width: 100%;
    padding: 0 172px;
}
.btn-toTop {
    position: fixed;
    display: grid;
    width: 40px;
    height: 43px;
    right: -50px;
    bottom: 40px;
    place-content: center;
    border: solid 1px $orange;
    border-radius: 5px;
    color: $white;
    background-color: $orange;
    outline: none;
    font-size: 40px;
    opacity: 0;
    cursor: pointer;
    transition: all 0.5s ease;
    z-index: 100;
    &:hover {
        background-color: transparent;
        color: $orange;
        transform: scale(1.05);
    }
    &.btn-active {
        opacity: 1;
        right: 40px;
    }
}
