#testimonials {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 100px 0 350px;
    h2 {
        @include par(40px, 600, $white);
        margin-bottom: 20px;
    }
    p {
        @include par(18px, 300, $white);
        width: 900px;
        margin-bottom: -50px;
    }
    .testimonials__slides {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 40px;
        cursor: pointer;
        img {
            width: 230px;
            height: 230px;
            border-radius: 100%;
            transform: translateY(110px);
        }
        &__item {
            width: 1000px;
            height: 500px;
            background-color: $lightBlack;
            padding-top: 150px;
            display: flex;
            flex-direction: column;
            align-items: center;
            h3 {
                @include par(25px, 700, $orange);
            }
            h4 {
                @include par(18px, 400, $white);
                margin-bottom: 54px;
            }
            p {
                width: 800px;
                @include par(18px, 300, $white);
                line-height: 1.8;
            }
        }
    }
}

@media (max-width: 1500px) {
    #testimonials {
        h2 {
            font-size: 35px;
        }
        p {
            font-size: 15px;
        }
    }
}
// ! Slider Editing

.slider-settings {
    width: 100%;
    height: 500px;
}

.carousel-dots {
    li {
        margin-bottom: 30px !important;
        margin-right: 70px !important;
        button::before {
            font-family: "slick";
            font-size: 40px;
            line-height: 20px;
            position: absolute;
            top: 0;
            left: 0;
            width: 70px !important;
            height: 16px !important;
            background-color: $lightGray;
            content: "" !important;
            text-align: center;
            opacity: 1 !important;
            color: black;
            -webkit-font-smoothing: antialiased;
            transition: all 0.3s ease !important;
            border-radius: 10px 10px;
        }
    }
}

.carousel-dots-active button:before {
    background-color: $orange !important;
}
