#hire-me {
    width: 100%;
    height: 440px;
    background-size: cover;
    background-image: url("./hire.png");
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h3 {
        @include par(22px, 300, $white);
        margin-bottom: 10px;
    }
    h2 {
        @include par(40px, 600, $white);
        margin-bottom: 40px;
    }
    .btn {
        position: relative;
        width: 241px;
        height: 83px;
        border: solid 1px $orange;
        border-radius: 15px;
        background-color: $orange;
        @include par(22px, 400, $white);
        outline: none;
        cursor: pointer;
        transition: all 0.3s ease;
        &:hover {
            background-color: transparent;
            color: $orange;
        }
    }
}
