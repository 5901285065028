.images__wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
        @include par(45px, 600, $white);
        margin-bottom: 30px;
        width: 100%;
        line-height: 2.1;
        background-color: $lightBlack;
        text-align: center;
        span {
            color: $orange;
            margin-right: 10px;
        }
    }
    &__imgs {
        display: grid;
        grid-template-columns: 350px 350px 350px;
        justify-content: center;
        gap: 40px;
        &__img {
            width: 350px;
            cursor: pointer;
            transition: all 0.5s ease;
            overflow: hidden;
            border-radius: 10px;
        }
        img {
            width: 350px;
            transition: all 0.3s ease;
            &:hover {
                transform: scale(1.2);
            }
        }
    }
}
